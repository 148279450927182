<template>

  <!-- Table Container Card -->
  <b-card
    title="Preventivi"
  >
    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <!-- <label>Entries</label> -->
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            v-if="productIdRef"
            variant="primary"
            :to="{ name: 'estimator-wizard', params: { id: productIdRef }}"
          >
            Nuovo
          </b-button>
          <b-button
            v-else
            variant="primary"
            :to="{ name: 'estimator-wizard', params: { id: -1 }}"
          >
            Nuovo
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Cerca..."
            />
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refProgramListTable"
      :items="list"
      responsive
      :fields="tableColumns"
      primary-key="Id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >


      <template #head(product)>
        <feather-icon
          icon="ShieldIcon"
          class="mx-auto"
        /> Prodotto
      </template>
      <template #head(actions)>
        <feather-icon
          icon="ZapIcon"
          class="mx-auto"
        /> Azioni
      </template>


      <!-- Column: Id -->
      <template #cell(numero_preventivo)="data">
        <b-link
          :to="{ name: 'edit-quotation', params:{id:data.item.Id} }"
        >
          {{
            data.item.numero_preventivo
          }}
        </b-link>
      </template>

      <!-- Column: Id -->
      <template #cell(create_date)="data">
        {{ (new Date(data.item.createDate)).toLocaleDateString() }}


      </template>

      <!-- Column: Id -->
      <template #cell(cliente)="data">
        {{ data.item.nome_cliente }} {{ data.item.cognome_cliente }}
      </template>
      <!-- Column: Id -->
      <template #cell(importo)="data">
        {{ data.item.totale }} €
      </template>

      <template #cell(stato_documento)="data">
        <b-badge
          v-if="data.item.isConfirmed=='Y'"
          variant="success"
        >
          Attivato
        </b-badge>
        <b-badge
          v-else-if="data.item.isConfirmed=='R'"
          variant="warning"
        >
          Confermato per l'attivazione
        </b-badge>
        <b-badge
          v-else
          variant="secondary"
        >
          Preventivo
        </b-badge>
      </template>

      <template
        #cell(user)="data"
      >
        {{ data.item.user.fullName }}
      </template>

      <!-- Column: Actions -->
      <!-- v-if="$can('read', 'Utenti')"
      <template #cell(actions)="data">

        <div
          v-if="data.item.isConfirmed!='Y'"
          class="text-nowrap"
        >
          <feather-icon
            :id="`program-row-${data.item.Id}-delete-icon`"
            icon="TrashIcon"
            size="16"
            class="mx-1"
            @click="confirmDeleteElementList(data.item)"
          />
          <b-tooltip
            title="Elimina"
            :target="`program-row-${data.item.Id}-delete-icon`"
          />


      </div>
    </b-table></b-card></template>-->

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Vedi da {{ dataMeta.from }} a {{ dataMeta.to }} su {{ dataMeta.of }} elementi</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalElements"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          />

        </b-col>

      </b-row>
    </div>
  </b-card>

</template>

<script>
import Vue from 'vue'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink, BPagination, BTooltip, BBadge,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import {
  onUnmounted, watch, onMounted, ref, computed,
} from '@vue/composition-api'
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'
// import ProgramAddModal from './ProgramAddModal.vue'
import { format } from 'date-fns'
import { getUserData } from '@/auth/utils'
import quotesStoreModule from './quotesStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,
    BTooltip,
    BBadge,

    vSelect,
    // ProgramAddModal,
  },
  props: {
    productId: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    const tableColumns = [
      { key: 'numero_preventivo', label: 'Documento', sortable: true },
      { key: 'cliente', label: 'Cliente', sortable: false },
      { key: 'importo', label: 'Importo', sortable: false },
      { key: 'create_date', label: 'Data', sortable: true },
      { key: 'stato_documento', label: 'Stato', sortable: true },
      //  { key: 'actions', label: 'Azioni' },
    ]

    if (Vue.prototype.$ability.can('read', 'Utenti')) {
      tableColumns.push({ key: 'user', label: 'Utente', sortable: true })
    }

    return {
      tableColumns,
    }
  },
  methods: {
    confirmDeleteElementList(item) {
      this.$swal({
        title: 'Attenzione!',
        text: `Sicuro di volere eliminare ${item.name}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, elimina',
        cancelButtonText: 'Annulla',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        async preConfirm() {
          try {
            await quotesStoreModule.actions.delete(null, item)
          } catch (err) {
            console.log(err)
            return null
          }
        },

      }).then(result => {
        if (result.value) {
          this.fetchData()
          this.$swal({
            icon: 'success',
            title: 'Eliminato!',
            text: 'Elemento eliminato con successo',
            customClass: {
              confirmButton: 'btn btn-success',
            },
            buttonsStyling: false,
          })
        } else {
          this.$swal({
            title: 'Errore!',
            text: 'Si è verificato un errore. Programma non eliminato',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      }).catch(err => {
        this.$swal({
          title: 'Errore!',
          text: 'Si è verificato un errore. Programma non eliminato',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },
  },
  setup(props) {
    const STORE_MODULE_NAME = 'app-wizard'

    const userData = ref(null)
    userData.value = getUserData()
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, quotesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })
    const productIdRef = ref(null)
    const list = ref([])
    const perPage = ref(10)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('Id')
    const isSortDirDesc = ref(true)
    const totalElements = ref(0)
    const fetchData = () => {
      console.log('user', userData.value)
      quotesStoreModule.actions.fetchList(null, {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        productId: productIdRef.value ?? '',
        userId: userData.value.id,
      }).then(resp => {
        console.log(resp.data)
        list.value = resp.data.quotes
        totalElements.value = resp.data.total
      }).catch(eer => {
        console.log("EEEEE", eer)
      })
    }

    const dataMeta = computed(() => {
      const localItemsCount = list.value ? list.value.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalElements.value,
      }
    })
    const { route, router } = useRouter()
    const routeParams = computed(() => route.value.params)
    if (routeParams.value.id) {
      productIdRef.value = routeParams.value.id
    }

    watch(routeParams, () => {
      try {
        if (routeParams.value.id) {
          productIdRef.value = routeParams.value.id
        }
      } catch (error) { }
    })

    watch([currentPage, perPage, searchQuery, sortBy, isSortDirDesc, productIdRef], () => {
      fetchData()
    })
    onMounted(() => {
      fetchData()
    })

    return {
      productIdRef, currentPage, perPageOptions, searchQuery, sortBy, perPage, isSortDirDesc, fetchData, list, dataMeta, totalElements,
    }
  },
}
</script>

    <style lang="scss" scoped>
    .per-page-selector {
      width: 90px;
    }

    .program-filter-select {
      min-width: 190px;

      ::v-deep .vs__selected-options {
        flex-wrap: nowrap;
      }

      ::v-deep .vs__selected {
        width: 100px;
      }
    }
    </style>

    <style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    </style>

